import { React, useRef, useState, useEffect } from "react";

import Footer2 from "./Footer2";
import HeaderLower from "./HeaderLower";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import secureLocalStorage from "react-secure-storage";

import RegisterService from "../services/register.service";

import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import Select from "react-select";
import UserformService from "../services/userform.service";
// import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

function RegisterForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE + " | Registration Form";
  const [fields, setFields] = useState({
    name: "",
    lname: "",
    emailid: "",
    address: "",
    p_address: "",
    city: "",
    state: "",
    zipcode: "",
    phone: "",
    showroom: "",
    sales: "",
  });

  const [errors, setErrors] = useState({});
  // const recaptchaRef = useRef(null);

  const [signoutTime, setSignoutTime] = useState(900000);
  const [states, setStates] = useState([]);
  const [storeId, setStoreId] = useState([
    {
      value: "01",
      label: "01 - Fort Lauderdale",
    },
    {
      value: "03",
      label: "03 - Tamarac",
    },
    {
      value: "05",
      label: "05 - Dadeland",
    },
    {
      value: "06",
      label: "06 - West Palm Beach",
    },
    {
      value: "07",
      label: "07 - Midtown",
    },
    {
      value: "08",
      label: "08 - Cutler Bay",
    },
    {
      value: "09",
      label: "09 - Pembroke Pines",
    },
    {
      value: "11",
      label: "11 - Boca Raton",
    },
    {
      value: "12",
      label: "12 - Hialeah",
    },
    {
      value: "16",
      label: "16 - North Miami",
    },
    {
      value: "17",
      label: "17 - Stuart",
    },
    {
      value: "18",
      label: "18 - Wellington",
    },
    {
      value: "19",
      label: "19 - Naples",
    },
    {
      value: "20",
      label: "20 - Fort Myers",
    },
    {
      value: "21",
      label: "21 - Spanish Springs",
    },
    {
      value: "22",
      label: "22 - Lake Sumter",
    },
    {
      value: "23",
      label: "23 - Ocoee",
    },
    {
      value: "24",
      label: "24 - Millennia",
    },
    {
      value: "25",
      label: "25 - Doral",
    },
    {
      value: "26",
      label: "26 - Altamonte Springs",
    },
    {
      value: "27",
      label: "27 - Hollywood",
    },
    {
      value: "28",
      label: "28 - Plant City",
    },
    {
      value: "50",
      label: "50 - Clearwater",
    },
  ]);

  // const [captcha, setCaptcha] = useState(false);

  const navigate = useNavigate();

  secureLocalStorage.removeItem("registerform");

  useEffect(() => {
    async function getStates() {
      let errors = {};

      try {
        const response = await UserformService.getStates();

        var states = response.data.data;
        const results = [];
        states.map((value) => {
          return results.push({
            value: value.code,
            label: value.state_name,
          });
        });
        await setStates([...results]);

        errors["voucher"] = response.data.response;
      } catch (err) {
        if (err.status === 429 || err.status === "429") {
          alert("Max Limit Reached. Please wait.");
        }
      }
    }

    // async function getStoreLocation() {
    //   let errors = {};

    //   try {
    //     //FIXME remove this service if not used
    //     const response = await RegisterService.getStoreLocation();

    //     var value = response.data.data;
    //     const results = [];
    //     value.map((value) => {
    //       return results.push({
    //         value: value.store_location,
    //         label: value.store_location + " - " + value.store_name,
    //       });
    //     });
    //     await setStoreId([...results]);

    //     if (response.data.status === 429 || response.data.status === "429") {
    //       alert("Max Limit Reached. Please wait.");
    //     }
    //     if (value.includes("redirect")) {
    //     } else {
    //       errors["questionnare"] = response.data.response;
    //     }
    //   } catch (err) {
    //     if (err.status === 429 || err.status === "429") {
    //       alert("Max Limit Reached. Please wait.");
    //     }
    //   }
    // }

    // if (localStorage.getItem("return_page") !== "true") {
    //   window.location.href = "/register";
    // } else {
    // }
    // getStoreLocation();
    getStates();

    window.history.pushState(null, document.title, window.location.href);
    const handleBackButton = (event) => {
      event.preventDefault();
    };
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  // timeout();
  // function timeout() {
  //   setTimeout(function () {
  //     logout();
  //   }, signoutTime);
  // }

  // const logout = () => {
  //   destroy();
  // };

  // const destroy = () => {
  //   localStorage.setItem("returnpage", true);
  //   localStorage.removeItem("return_page");
  //   localStorage.removeItem("registerForm");
  //   localStorage.removeItem("isRegister");
  // };

  const ref = useRef();

  const handleChange = (e) => {
    let { value, name } = e.target;
    if (name === "store_location" && value === "true") {
      value = "";
    }

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const saveErrors = (err) => {
    setErrors(err);
  };

  const [resetKey, setResetKey] = useState(0);
  const [resetKey1, setResetKey1] = useState(0);

  const submituserRegistrationForm = (e) => {
    e.preventDefault();

    let data = {
      fname: fields?.name,
      lname: fields?.lname,
      email: fields?.emailid,
      address: fields?.address,
      p_address: fields?.p_address,
      city: fields?.city,
      state: fields?.state,
      zip: fields?.zipcode,
      sales: fields?.sales,
      phone: fields?.phone,
      showroom: fields?.showroom,
      // captcha: captcha,
    };

    try {
      const responseuserform = RegisterService.regData(data);
      responseuserform
        .then((response) => {
          var valueUserform = response?.data?.status;

          toast.success("Registered Successfully!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            setFields({
              name: "",
              lname: "",
              emailid: "",
              address: "",
              p_address: "",
              city: "",
              state: "",
              zipcode: "",
              phone: "",
              showroom: "",
              sales: "",
            });
            // setCaptcha(false);
            setErrors([]);
            setResetKey((prevKey) => prevKey + 1);

            setResetKey1((prevKey) => prevKey + 1);
            // if (recaptchaRef.current) {
            //   recaptchaRef.current.reset();
            // }
            // window.location.reload();
          }, 1000);

          // Swal.fire({
          //   title: "Successful!",
          //   text: "Form Submitted!",
          //   type: "success",
          //   icon: "success",
          // }).then((result) => {
          //   secureLocalStorage.setItem(
          //     "registerform",
          //     JSON.stringify({
          //       name: fields.name,
          //       lname: fields.lname,
          //       emailid: fields.emailid,
          //       address: fields.address,
          //       p_address: fields.p_address,
          //       city: fields.city,
          //       state: fields.state,
          //       zipcode: fields.zipcode,
          //       sales: fields.sales,
          //       phone: fields.phone,
          //       showroom: fields.showroom,
          //     })
          //   );
          //   localStorage.removeItem("return_page");
          //   localStorage.setItem("isRegister", true);
          //   navigate("/register/registration-thankyou");
          // });
        })
        .catch((err) => {
          let errorData = {};
          errorData["name"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.fname &&
            err?.response?.data?.message.fname[0];
          errorData["lname"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.lname &&
            err?.response?.data?.message.lname[0];
          errorData["emailid"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.email &&
            err?.response?.data?.message.email[0];
          errorData["address"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.address &&
            err?.response?.data?.message.address[0];
          errorData["p_address"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.p_address &&
            err?.response?.data?.message.p_address[0];
          errorData["city"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.city &&
            err?.response?.data?.message.city[0];
          errorData["state"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.state &&
            err?.response?.data?.message.state[0];
          errorData["zipcode"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.zip &&
            err?.response?.data?.message.zip[0];
          errorData["sales"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.sales &&
            err?.response?.data?.message.sales[0];
          errorData["phone"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.phone &&
            err?.response?.data?.message.phone[0];
          errorData["showroom"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.showroom &&
            err?.response?.data?.message.showroom[0];
          errorData["checkederrorcaptcha"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.captcha &&
            err?.response?.data?.message.captcha[0];

          errorData["checkederrortwo"] =
            err?.response?.data?.message &&
            err?.response?.data?.message.size &&
            err?.response?.data?.message.size[0];
          saveErrors(errorData);
          ref?.current?.focus();
        });
    } catch (err) {
      alert(err);
      if (err.response.status === 429 || err.response.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      let errorData = {};
      errorData["name"] = err?.response?.data?.message.fname[0];
      errorData["lname"] = err?.response?.data?.message.lname[0];
      errorData["emailid"] = err?.response?.data?.message.phone[0];
      errorData["address"] = err?.response?.data?.message.address[0];
      errorData["p_address"] = err?.response?.data?.message.p_address[0];
      errorData["city"] = err?.response?.data?.message.city[0];
      errorData["state"] = err?.response?.data?.message.state[0];
      errorData["sales"] = err?.response?.data?.message.sales[0];
      errorData["zipcode"] = err?.response?.data?.message.zip[0];
      errorData["phone"] = err?.response?.data?.message.phone[0];
      errorData["showroom"] = err?.response?.data?.message.showroom[0];
      errorData["checkederrorcaptcha"] =
        err?.response?.data?.message.captcha[0];
      // errorData["sales_name"] = err?.response?.data?.message.sales_name[0];
      saveErrors(errorData);
    }
  };
  // const validate = (e) => {
  //   let errors = {};

  //   let isformIsValid = true;
  //   if (!captcha) {
  //     isformIsValid = false;
  //     errors["checkederrorcaptcha"] = "Captcha not verified";
  //     saveErrors(errors);
  //     return isformIsValid;
  //   }
  //   return isformIsValid;
  // };

  // function onCaptchaChange(value) {
  //   if (value === null) {
  //     setCaptcha(false);
  //   } else {
  //     setCaptcha(true);
  //   }
  // }

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="container-fluid">
        {/* <HeaderInner/> */}
        <HeaderLower />
        <div id="main-registration-container">
          <div className="white-box form" id="font-style">
            <h1>Register your guest for their Reward Card!</h1>
            <hr />
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
              // onClick={this.popup}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    Customer First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Customer First Name"
                    style={errors.name ? { border: "1px solid red" } : null}
                    name="name"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.name ? ref : null}
                    value={fields.name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.name}</div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    placeholder="Customer Last Name"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname || ""}
                    ref={errors.lname && !errors.name ? ref : null}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname && !errors.name ? errors.lname : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Email <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    ref={
                      errors.emailid && !errors.lname && !errors.name
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid && !errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    placeholder="Customer Email"
                    value={fields.emailid || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid && !errors.lname && !errors.name
                      ? errors.emailid
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Phone # <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    style={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="phone"
                    placeholder="Customer Phone"
                    value={fields.phone || ""}
                    ref={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                    maxLength={10}
                  />
                  <div className="errorMsg">
                    {" "}
                    {errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.phone
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label required className="formsub">
                    Customer Address (Optional)
                    <span className="red_point"></span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Customer Address"
                    id="address"
                    className="searchBox_deals vc-validate"
                    // autoFocus=""
                    value={fields.address || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.address
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Apartment/Suite # (Optional)
                    <span className="red_point"></span>
                  </label>
                  <input
                    type="text"
                    name="p_address"
                    id="p_address"
                    placeholder="Apartment/Suite #"
                    // autoFocus=""
                    value={fields.p_address || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.p_address &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.p_address
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    City (Optional)<span className="red_point"></span>
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    placeholder="City"
                    value={fields.city || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.city &&
                    !errors.p_address &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.city
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    State (Optional)<span className="red_point"></span>
                  </label>
                  <Select
                    key={resetKey}
                    classNamePrefix="react-select"
                    className={`select_1 dropdown ${
                      errors.state &&
                      !errors.City &&
                      !errors.p_address &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? "error-select-search"
                        : ""
                    }`}
                    placeholder="Select State"
                    options={states}
                    isSearchable={true}
                    isClearable={true}
                    name="state"
                    ref={
                      errors.state &&
                      !errors.City &&
                      !errors.p_address &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["state"]: selectedOption.value,
                          };
                        });
                      } else {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["state"]: "",
                          };
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Zip Code (Optional)<span className="red_point"></span>
                  </label>
                  <input
                    type="text"
                    // onkeypress="return isNumberKey(event)"
                    id="zipcode"
                    name="zipcode"
                    placeholder="Zip Code"
                    value={fields.zipcode || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.zipcode &&
                    !errors.state &&
                    !errors.City &&
                    !errors.p_address &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.zipcode
                      : null}
                  </div>
                  {/* <span style="color:red" id="zip_msg"></span> */}
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Showroom Location<span className="red_point"> *</span>
                  </label>

                  <Select
                    key={resetKey1}
                    classNamePrefix="react-select"
                    className={`select_1 dropdown ${
                      errors.showroom &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.City &&
                      !errors.p_address &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? "error-select-search"
                        : ""
                    }`}
                    placeholder="Select Showroom Location"
                    options={storeId}
                    isSearchable={true}
                    isClearable={true}
                    name="showroom"
                    ref={
                      errors.showroom &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.City &&
                      !errors.p_address &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["showroom"]: selectedOption.value,
                          };
                        });
                      } else {
                        setFields((prevValue) => {
                          return {
                            ...prevValue,
                            ["showroom"]: "",
                          };
                        });
                      }
                    }}
                  />

                  <div className="errorMsg">
                    {errors.showroom &&
                    !errors.zipcode &&
                    !errors.state &&
                    !errors.City &&
                    !errors.p_address &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.showroom
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Sales Associate #<span className="red_point"> *</span>
                  </label>
                  <input
                    type="text"
                    id="sales"
                    style={
                      errors.sales &&
                      !errors.showroom &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.City &&
                      !errors.p_address &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="sales"
                    placeholder="Sales Associate #"
                    value={fields.sales || ""}
                    ref={
                      errors.sales &&
                      !errors.showroom &&
                      !errors.zipcode &&
                      !errors.state &&
                      !errors.City &&
                      !errors.p_address &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {errors.sales &&
                    !errors.showroom &&
                    !errors.zipcode &&
                    !errors.state &&
                    !errors.City &&
                    !errors.p_address &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.sales
                      : null}
                  </div>
                </div>

                <br />

                <div className="clearfix"></div>
              </div>
              {/* <div className="overflow-hider">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                  onChange={onCaptchaChange}
                  ref={recaptchaRef}
                />
              </div>
              <div className="errorMsg">
                {!errors.sales &&
                !errors.showroom &&
                !errors.zipcode &&
                !errors.state &&
                !errors.City &&
                !errors.p_address &&
                !errors.address &&
                !errors.phone &&
                !errors.emailid &&
                !errors.lname &&
                !errors.name
                  ? errors.checkederrorcaptcha
                  : null}
              </div> */}

              <div className="alert alert-info">
                Limit 1 per household, while supplies last. Some restrictions
                apply, see store for detail.
              </div>
              <input
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn2 button Formfirst"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}
export default RegisterForm;
